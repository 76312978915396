import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable()
export class AuthenticationGuard implements CanActivate {

  constructor(
    private router: Router,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let token = '';
    try {
      token = sessionStorage.getItem('token');
    } catch (e) {
      // console.log(e);
    }
    if (token) {
      return true;
    }
    const isAdmin = sessionStorage.getItem('isAdmin');
    const isReseller = sessionStorage.getItem('isReseller');
    




    if (window.location.pathname.indexOf('/dashboard/swiftTransfer') !== -1 &&
    window.location.pathname.indexOf('/dashboard/add_beneficiary') !== -1  &&
    window.location.pathname.indexOf('/dashboard/sepaTransfer') !== -1  &&
    window.location.pathname.indexOf('/dashboard/ibanTransfer') !== -1  &&
    window.location.pathname.indexOf('/dashboard/currencyConverion') !== -1  
    ) {
      if(sessionStorage.getItem('isActiveAccountsAvailable')){
        sessionStorage.removeItem('isActiveAccountsAvailable');
      }
      
    }
    if (isAdmin == 'true') {
        this.router.navigateByUrl('/userLogin/admin');
      }else if (isReseller == 'true') {
        this.router.navigateByUrl('/userLogin/reseller');
      } else {
        this.router.navigateByUrl('/userLogin/login');
      }
    return;
  }
}
