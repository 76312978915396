import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../services/data.service'
import { UserApiService } from '../services/user-api.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from '@accubits/spinner';
import { PermissionService } from '../services/permission.service';
import { map } from 'rxjs-compat/operator/map';
@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {
  currentUrl: any = '';
  isEmployee: any = '';
  isVerify = false;
  onBoardedEmployee=false;
  onboardingUser = false;
  permission;
  resellerLoginType;
  constructor(
    public router: Router,
    private apiService: UserApiService,
    private toastr: ToastrService,
    public dataService: DataService,
    private spinner: SpinnerService,
    private permissionService: PermissionService
  ) {
    /*router.events.subscribe((event: any) => {
      // console.log(event);
      if (event instanceof NavigationEnd ) {
        this.currentUrl = event.url;
        // console.log('1',this.currentUrl);
      }
    });*/
  }

  ngOnInit() {
    this.resellerLoginType=sessionStorage.getItem('resellerLoginType');
    this.isEmployee = sessionStorage.getItem('isEmployee');
    // this.isVerify = sessionStorage.getItem('verify')
    this.isVerify = (sessionStorage.getItem('verify') == '2') ?  true:false;;
    // console.log(this.isVerify);
    this.onboardingUser = sessionStorage.getItem('accountRole')=='onboardingUser' ? true:false;
    
    if (this.router.url == '/dashboard/overview') {
      this.currentUrl = this.router.url;
    }
    if (this.router.url == '/resellerDashboard/overview') {
      this.currentUrl = this.router.url;
    }
    this.permissionService.getPrivilages();

    this.permissionService.privilageList.subscribe(res => {
      this.permission = this.permissionService.apiResult;
      
      // this.permissionService.apiResult
    })
    // this.onBoardedEmployee=sessionStorage.getItem('accountRole')=='employee' ? true:false;
  }

  verifyWarning() {
    this.toastr.warning("This menu can be accessed after Account verification is completed");
    return;
  }

}
