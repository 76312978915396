import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, ActivatedRoute, Router } from '@angular/router';
import { UserApiService } from './user-api.service';
import { map } from 'rxjs-compat/operator/map';

@Injectable()
export class PermissionService {
  count: number = 0;
  privileges = {};
  writeBtn = true;
  apiResult = {};
  routeMap = [
    { route: '/dashboard/corporates', value: 'Corporate' },
    { route: '/dashboard/additionalDocs', value: 'Corporate' },
    { route: '/dashboard/profile', value: 'Corporate' },
    { route: '/dashboard/employees', value: 'Employees' },
    { route: '/dashboard/employeeProfile', value: 'Employees' },
    { route: '/dashboard/subscriptions', value: 'Subscriptions' },
    { route: '/dashboard/accounts', value: 'Accounts' },
    { route: '/dashboard/payments', value: 'Payments' },
    { route: '/dashboard/overview', value: 'Overview' }
  ];
  private privilageObs = new BehaviorSubject(this.privileges);
  privilageList = this.privilageObs.asObservable();

  constructor(
    private router: Router,
    private apiService: UserApiService
  ) {
    // console.log('fgfgffgft');
    setTimeout(() => {
      // this.getPrivilages();
    }, 1000);
  }

  getCount() {
    this.apiService.functionGET('notification/getCount').subscribe((res) => {
      if (res['status']) {
        this.count = res['data']['notificationCount'];
      }
    });
  }
  getPrivilages() {
    // // console.log('get func');
    if (sessionStorage.getItem('isEmployee')) {
      this.apiService.functionGET('employee/getAccessDetails').subscribe((res) => {
        if (res['status']) {
          // // console.log('get func 2222');
          //  this.setPrivilage(res['data']['privileges']);
          const apiResult = res['data']['privileges'];
          apiResult.map(x => this.apiResult[x['pageInfo.name']] = x.permission);
          this.privileges = this.getPermission(apiResult);

          // // console.log('qwertyuio', this.privileges);
          this.privilageObs.next({ ...this.privileges });
          // // console.log('rwew', this.privilageObs);
        }
      }
      );
    } else {
      this.privileges = this.getPermissionValue(6);
      // // console.log('dfrfgdrfgt');
      // // console.log('qwertyuio',this.privileges);
      this.privilageObs.next({ ...this.privileges });
      // // console.log('rwew', this.privilageObs);

    }

  }
  setPrivilage(value) {
    this.privilageObs.next({ permission: value });
    // // console.log('value', value);
  }
  set permissionList(value) {
    // // console.log('valueeeee', value);
    this.privileges = value;
  }

  getPermission(privileges?) {
    // // console.log('route', this.router.url);
    const route = this.routeMap.find(x => x.route === this.router.url);
    // // console.log('routeewwww', route);
    if (route) {
      // // console.log('routeewwww', route);
      const permission = privileges.find(x => x['pageInfo.name'] === route.value);
      if (permission) {
        // // console.log('permission', permission);
        if (permission.permission === 0) {
          if (route.value === 'Overview') {
            // // console.log('dfdf');
            this.router.navigateByUrl('/dashboard/settings');
          } else {
            this.router.navigate(['/dashboard/overview']);
          }
        } else {
          return this.getPermissionValue(permission.permission);
        }
      }

    }

  }
  getPermissionValue(value) {
    return {
      read: value === 4 || value === 6,
      write: value === 6,
    };

  }
}
