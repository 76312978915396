import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {SharedModule} from './shared/shared.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {SpinnerModule, SpinnerService} from '@accubits/spinner';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TokenInterceptor} from './shared/interceptor/http.interceptor';
import {AuthenticationGuard} from './shared/authgaurd/auth.guard';
import {AgmCoreModule} from '@agm/core';
import { PaymentResponseComponent } from './payment-response/payment-response.component';
import { CancelComponent } from './cancel/cancel.component';
import { PaymentInitiateComponent } from './payment-initiate/payment-initiate.component';
import { FormsModule } from '@angular/forms';
import { MaintenanceComponent } from './maintenance/maintenance.component';
// import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [
    AppComponent,
    PaymentResponseComponent,
    CancelComponent,
    PaymentInitiateComponent,
    MaintenanceComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    SpinnerModule,
    HttpClientModule,
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBLiU9kqvxEz1aLECa8otg5k-7JU8MvVdg',
      libraries: ['places']
    }),
    ToastrModule.forRoot(
      {
        preventDuplicates: true
      }
    )
  ],
  providers: [
    SpinnerService,
    AuthenticationGuard,
    // CookieService,
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule {
}
