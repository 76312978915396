import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserApiService} from '../shared/services/user-api.service';

import {ToastrService} from 'ngx-toastr';
import {SpinnerService} from '@accubits/spinner';
import { ThemeService } from 'ng2-charts';
@Component({
  selector: 'app-payment-initiate',
  templateUrl: './payment-initiate.component.html',
  styleUrls: ['./payment-initiate.component.css']
})
export class PaymentInitiateComponent implements OnInit {
  select: boolean;
  otp: boolean;
  actionAvailable: boolean =true;
  actionMsg:any='';
  success;

  userOtp: '';
  referenceId: '';
  payerID: '';
  paymentId: '';
  paymentFrom='';
  paymentTo='';
  PaymentType='';
  paymentAmount='';
  transactionTypes=['International Payment',
    'Domestic Payment ( SEPA )',
    'Intech Finance Account Transfer ( Intra bank transfer )',
    'Currency Conversion']
  errorMessage = 'Loading...';
  constructor(
    private router : Router,
    private apiService : UserApiService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: SpinnerService
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      // console.log(params);
      if (params['paymentId'] && params['authId']) {
        this.paymentId = params['paymentId'];
        this.payerID = params['authId'];
        // this.data.payerId = params['PayerID'];
        this.getDetails();
        // this.id = true;
      } else {
        this.errorMessage = 'Invalid Url';
      }
    }
  );
  }
  getDetails() {
    this.spinner.show();
    let data = {
      paymentId:this.paymentId,
      payerId:this.payerID,
    };
    this.apiService.functionPOST('company/paymentDetailsByGuid',data).subscribe((res) => {
      // console.log(res)
        if (res['status']) {
          this.actionAvailable = true;
          this.actionMsg = '';
  
          this.PaymentType=this.transactionTypes[res['message'].transactionType-1]
          this.paymentAmount=res['message'].amount
          if(res['message'].transactionType==4){
            this.paymentFrom=res['message'].fromCurrency
            this.paymentTo=res['message'].toCurrency
          }else{
            this.paymentFrom=res['message'].senderName
            this.paymentTo=res['message'].recipientName
          }
          this.spinner.hide();
        }
        this.spinner.hide();
      }, (err) => {
        // console.log(err);
        this.actionAvailable = false;
        this.actionMsg = err.message;
        //this.toastr.error(err.message);
        this.spinner.hide();
        this.cancelBack();
        if (err.error.message && err.error.message !== 'No details found') {
          this.toastr.error(err.error.message);
          this.errorMessage = err.error.message;
        } else if (err.errors) {
          for (const item of err.errors) {
            this.toastr.error(item.msg);
          }
        }
        this.spinner.hide();
      }
    );
  }
  getOtp() {
    this.spinner.show();
    let data = {
      paymentId:this.paymentId,
      payerId:this.payerID,
    };
    this.apiService.functionPOST('company/getOtpForApproval',data).subscribe((res) => {
      // console.log(res)
        if (res['status']) {
          this.userOtp = res.data.otp;
          this.referenceId = res.data.referenceId;
          this.toastr.success(res['message']);
          this.spinner.hide();
        }
        this.spinner.hide();
      }, (err) => {
        // console.log(err);
        this.toastr.error(err.message);
        this.spinner.hide();
        this.cancelBack();
        if (err.error.message && err.error.message !== 'No details found') {
          this.toastr.error(err.error.message);
          this.errorMessage = err.error.message;
        } else if (err.errors) {
          for (const item of err.errors) {
            this.toastr.error(item.msg);
          }
        }
        this.spinner.hide();
      }
    );
  }
  cancelOtp() {
    this.spinner.show();
    let data = {
      paymentId:this.paymentId,
      payerId:this.payerID,
    };
    this.apiService.functionPOST('company/rejectTransaction',data).subscribe((res) => {
      // console.log(res)
        if (res['status']) {
          this.userOtp = '';
          this.referenceId = '';
          this.toastr.success(res['message']);
          this.select = true;
          this.otp = false;
          this.spinner.hide();
        }
      }, (err) => {
        // console.log(err);
        if (err.error.message && err.error.message !== 'No details found') {
          this.toastr.error(err.error.message);
          this.errorMessage = err.error.message;
        } else if (err.errors) {
          for (const item of err.errors) {
            this.toastr.error(item.msg);
          }
        }
        this.spinner.hide();
      }
    );
  }
  validateOtp() {
    this.spinner.show();
    let data = {
      referenceId:this.referenceId,
      otp:this.userOtp,
    };
    this.apiService.functionPOST('company/verifyMobileOTP',data).subscribe((res) => {
      // console.log(res)
        if (res['status']) {
          this.success= true;
          this.toastr.success(res['message']);
          this.spinner.hide();
        }
      }, (err) => {
        // console.log(err);
        if (err.message && err.message !== 'No details found') {
          this.toastr.error(err.message);
          this.errorMessage = err.message;
        } else if (err.errors) {
          for (const item of err.errors) {
            this.toastr.error(item.msg);
          }
        }
        this.spinner.hide();
      }
    );
  }
  cancel(){
    this.cancelOtp();
  }
  approve(){
    this.getOtp();
    this.otp = true;
  }
  cancelBack(){
    this.otp = false;
    this.select = false;
  }
  home(){
    window.close();
  }
  OK(){
    if(!this.userOtp){
      return;
    }
    this.validateOtp();
  }
}
