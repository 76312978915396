import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {DataService} from '../shared/services/data.service';
import {SpinnerService} from '@accubits/spinner';
import {ActivationService} from '../shared/services/activation.service';

@Component({
  selector: 'app-payment-response',
  templateUrl: './payment-response.component.html',
  styleUrls: ['./payment-response.component.css']
})
export class PaymentResponseComponent implements OnInit {
  declined = false;
  success = false;
  id = false;
  subscription_id: '';
  payerID: '';
  paymentId: '';
  remarks='';
  errorMessage = 'Loading...';
  data = {
    subscription_id: ''
  };
  header = {
    latitude: '',
    longitude: ''
  }

  constructor(private actService: ActivationService,
              private toastr: ToastrService,
              public dataService: DataService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: SpinnerService) {
  }

  ngOnInit() {
    this.getLocation();
    this.activatedRoute.queryParams.subscribe((params) => {
        // console.log(params);
        if ((params['subscription_id'] || params['paymentId']) && params['token']) {
          // console.log('p',params['subscription_id']);
          this.subscription_id = params['subscription_id'];
          this.paymentId = params['paymentId'];
          this.payerID = params['PayerID'];
          // this.data.payerId = params['PayerID'];
          setTimeout(() => {
            this.getStatus();
          }, 1000);
          // this.id = true;
        } else {
          this.id = false;
          this.errorMessage = 'Invalid Url';
        }
      }
    );
  }

  getLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        // console.log(position);
        this.header.latitude = (position.coords.latitude).toString();
        this.header.longitude = (position.coords.longitude).toString();
      }, (err) => {
        // console.log(err);
      }, {maximumAge: 60000, timeout: 25000, enableHighAccuracy: true});
    } else {
      // console.log('no location');
    }
  }

  gotoDash() {
    this.router.navigateByUrl('dashboard/overview');
  }

  getStatus() {
    this.spinner.show();
    let data = {
      subscriptionId:this.subscription_id,
      paymentId:this.paymentId,
      payerId:this.payerID,
    };
    this.actService.checkPaypalResponse(data, this.header).subscribe((res) => {
        if (res['status']) {
          if(res['data']['status']=='failed'){
            this.remarks=res['remarks']
          }
          this.id = true;
          this.success = true;
          this.toastr.success(res['message']);
          this.spinner.hide();
          this.dataService.activated = 1;
          sessionStorage.removeItem('activated');
          sessionStorage.setItem('activated', '1');
        }
      }, (err) => {
        // console.log(err);
        if (err.error.message && err.error.message !== 'No details found') {
          this.toastr.error(err.error.message);
          this.errorMessage = err.error.message;
        } else if (err.errors) {
          for (const item of err.errors) {
            this.toastr.error(item.msg);
          }
        }
        this.id = true;
        this.declined = true;
        this.spinner.hide();
      }
    );
  }

}